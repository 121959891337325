<template>
  <div class="project-column-text">
    <div class="row justify-content-end NexaText-Light" v-if="position==='right'">
      <div class="col col-md-12 col-lg-9">
        <p v-if="content" v-bind:style="{ columns: columns }" ><small>{{content}}</small></p>
      </div>
    </div>
    <div class="row justify-content-start NexaText-Light" v-if="position==='left'">
      <div class="col col-md-12 col-lg-9">
        <p v-if="content" v-bind:style="{ columns: columns }" ><small>{{content}}</small></p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ProjectColumnText',
      props: {
      columns: Number,
      content: String,
      position: String
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  .project-column-text{
    padding-top: 30px;
    padding-bottom: 30px;
    line-height: 20px;
    .mobile-view{
      line-height: 30px;
    }
  }
</style>
